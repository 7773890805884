@import '../Theme/constants';
@import './assets/animations';

.loading {
  width: 35px;
  height: 35px;
  margin-top: 112px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

.onfidoCamEncryptionBody {
  text-decoration: none;
}
