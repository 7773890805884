@use '~@onfido/castor';

:global(.ods-field-label) > .componentContainer {
  margin: castor.space(0.5) 0 castor.space(-0.5);
}

.componentContainer {
  display: flex;
  flex-direction: row;
}

.inputContainer {
  box-sizing: border-box;

  & + & {
    margin-left: castor.space(1.25);
  }

  &.small {
    width: castor.space(7.5);
  }

  &.large {
    width: castor.space(9.5);
  }
}
