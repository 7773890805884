@import '../Theme/constants';

.camera {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.docAutoCaptureFrame {
    display: block;
    justify-content: flex-start;
  }
}

.container {
  overflow: hidden;
}

.docAutoCaptureFrame .container {
  position: relative;
}

.container::before {
  content: '';
  display: block;
  padding-top: 70%;
}

.overlay {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 0;
}

.video {
  display: block;
  height: 100%;
  z-index: 0;
  /* Use `object-fit: cover` on every browser but Edge */
  @supports not (-ms-ime-align: auto) {
    width: 100%;
    top: 0;
    left: 0;
    margin: auto;
    object-fit: cover;
  }

  .docLiveCaptureFrame & {
    //  NOTE: object-fit: cover causes camera view to appear very zoomed in on some Android devices,
    //        e.g. Huawei P40, P30.
    //        For iOS devices, the camera view is very slightly zoomed in when object-fit: cover is applied.
    //        With object-fit: initial, i.e. use the browser default, zoom is gone but camera view is still fullscreen.
    //        Note also that if viewed on a browser's device emulator camera view displays differently to on actual device.
    //        The user also does not get the full camera view for both Android & iOS devices.
    //        See MDN documentation https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit
    object-fit: initial; // set to browser default, setting to "none" still results in a zoomed in view
  }
}

.actions {
  position: absolute;
  z-index: 1000;
  bottom: 48 * $unit;
  left: 16 * $unit;
  right: 16 * $unit;

  &.disabled {
    z-index: 0;
  }
}

.btn {
  cursor: pointer;
  outline-offset: 6 * $unit;
  background-color: var(--onfido-color-background-button-camera);
  font-size: inherit;
  border-radius: 50%;
  border: 3 * $unit solid $color-black;
  box-shadow: 0 0 0 4 * $unit $color-white;
  height: 56 * $unit;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  width: 56 * $unit;

  &:hover {
    background-color: var(--osdk-color-background-button-camera-hover);
  }

  &:active {
    background-color: var(--osdk-color-background-button-camera-active);
  }

  &:disabled {
    display: none;
  }
}

.webcamContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .docLiveCaptureFrame & {
    left: 0;
    top: 50%;
    margin: auto;
    transform: translate(0%, -50%);
    height: auto;
    width: 100%;
  }

  /* Only apply styles on Edge where there is no `object-fit:cover` support for video */
  @supports (-ms-ime-align: auto) {
    left: 50%;
    margin: auto;
    transform: translate(-50%, 0%);
  }
}
