@import '../Theme/constants';

.fallbackButton {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: inherit;
  font: inherit;
  text-decoration: underline;
  background: none;
  border: 0;
}
