@import '../Theme/constants';

/* Document */

.document {
  border: 1px solid var(--ods-color-border-info); /* Doc Autocapture border colour */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.document svg {
  height: 100%;
  width: 100%;
}

.document svg > path {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.document .fullScreen {
  fill: $color-camera-overlay;
  stroke: transparent;
}

.document .hollow {
  fill: transparent;
  stroke: $color-white;
  stroke-width: 0.25;
}

.document .placeholder {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  &.card {
    background-image: url('./assets/card-placeholder.svg');
  }

  &.passport {
    background-image: url('./assets/passport-placeholder.svg');
  }

  &.frPaperDl {
    background-image: url('./assets/fr-dl-placeholder.svg');
  }

  &.itPaperId {
    background-image: url('./assets/it-id-placeholder.svg');
  }
}

.document .footer,
.document .header {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}

/* Face */

@mixin position-hole($scale) {
  $overlay-width: 740 * $unit;
  $overlay-height: 1800 * $unit;
  $hole-width: 264 * $unit;
  $hole-height: 328 * $unit;
  $offset-y: 105 * $unit;

  background-size: ($overlay-width * $scale) ($overlay-height * $scale);
  background-position: 50%
    calc(#{($hole-height - $overlay-height) * $scale * 0.5 + $offset-y});
}

.face {
  @include position-hole(1);
  background-image: url('./assets/face-with-hole.svg');
  background-repeat: no-repeat;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  @media (--smaller-viewport) {
    @include position-hole(0.85);
  }

  .isWithoutHole & {
    background-image: url('./assets/face-without-hole.svg');
  }
}

.ariaLabel {
  opacity: 0;
}
