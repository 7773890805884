@import '../../Theme/constants';

.countrySelector {
  :global(.ods-select) {
    max-width: unset;
  }
}

.countrySelector {
  :global(.ods-select-output) {
    display: flex;
  }
}

.countryFlag {
  position: absolute;
  top: 12 * $unit;
  left: 12 * $unit;
  width: 24 * $unit;
  height: 24 * $unit;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

.countryLabel {
  padding-left: 30 * $unit;
  display: flex;
  align-items: center;
}
