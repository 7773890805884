@import '../../Theme/constants';

.paperIdFlowSelector {
  align-items: stretch;
  align-self: flex-end;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16 * $unit;
  text-align: left;
  z-index: 3;
}

.paperIdFlowSelector .title {
  margin-bottom: 16 * $unit;
}

.paperIdFlowSelector button {
  align-items: center;
  background-color: $color-white;
  border-radius: 4 * $unit;
  border: 1px solid rgba(var(--ods-color-neutral-700));
  display: flex;
  font-family: var(--osdk-font-family-body);
  font-size: var(--onfido-font-size-base);
  height: 80 * $unit;
  margin-top: 8 * $unit;
  padding: 0 24 * $unit;

  &:active {
    background-color: var(--onfido-color-background-button-custom);
  }

  .text {
    flex: 1;
    padding: 0 24 * $unit;
    text-align: left;
  }

  .icon,
  .chevron {
    align-self: stretch;
    width: 24 * $unit;
  }
}

.cardId .icon {
  background: url('./assets/card-id.svg') no-repeat center;
}

.paperId .icon {
  background: url('./assets/paper-id.svg') no-repeat center;
}

.chevron {
  background: url('./assets/chevron-right.svg') no-repeat center;
}
