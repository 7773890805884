@import '../../Theme/constants';

.content {
  font-size: var(--onfido-font-size-base);
  position: relative;
  margin-bottom: 16 * $unit;
  flex: 1;
  flex-direction: column;
  display: flex;
  margin: 0 48 * $unit auto;

  @media (--small-viewport) {
    margin: 0 0 auto;
  }
}

.subTitle {
  font-size: var(--onfido-font-size-large);
  font-weight: 400;

  &::before {
    content: '';
    display: inline-block;
    width: 16 * $unit-small;
    height: 16 * $unit-small;
    background-image: url('./assets/warningSmall.svg');
    background-size: contain;
    vertical-align: middle;
    line-height: 1;
    margin-right: 5 * $unit-small;
    margin-top: -2 * $unit-small;
  }
}

.bolder {
  font-weight: 600;
}

.makeSure {
  color: var(--osdk-color-content-body);
  font-weight: 600;
  margin-bottom: 8 * $unit;
  text-align: left;
}

.docImageContainer {
  position: relative;
  flex: 1;
  width: 340 * $unit;
  margin: 0 auto;
  @media (--small-viewport) {
    width: 100%;
  }
}

.docImage {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.requirement {
  display: flex;
  padding-bottom: 16 * $unit;
  padding-left: 16 * $unit;
  position: relative;

  &::before {
    content: '';
    background-color: var(--osdk-color-background-alert-info);
    border-radius: 50%;
    display: inline-block;
    height: 6 * $unit;
    width: 6 * $unit;
    position: absolute;
    left: 0;
    top: 6 * $unit;
  }
}

.label {
  fill: var(--osdk-color-content-body);
  font-size: var(--onfido-font-size-small);
}
