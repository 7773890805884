.container {
  position: relative;
  cursor: pointer;
}

.input {
  bottom: 0;
  left: 0;
  /* This input should always be hidden.
  Highest specificity added to prevent the host app from overriding this style by mistake */
  display: none !important;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
