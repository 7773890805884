@import '../Theme/constants';

.controls {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.icon {
  display: none;
}

@media (--long-viewport) {
  .icon {
    display: block;
    height: 64 * $unit;
    background-image: url('./assets/icon-flip-back.svg');
  }
}
