@import '../Theme/constants';

.expanded {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background-color: $color-white;
}

.imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  min-height: 100%;
  min-width: 100%;
  object-fit: contain;
  object-position: center;
  display: block;
  touch-action: none;
}

.container {
  /*
  HACK this container is only necessary in order to increase
  the specifycity when compared to the Button variant class used
  */
  .button {
    position: absolute;
    left: 50%;
    bottom: 0%;
    transform: translate(-50%, -50%);
    padding: 5 * $unit 15.4 * $unit;
    border: 0;

    &::before {
      content: '';
      display: inline-block;
      width: 12 * $unit;
      height: 12 * $unit;
      background-image: url('./assets/enlarge.svg');
      background-repeat: no-repeat;
      background-size: contain;
      vertical-align: middle;
      margin-top: -2 * $unit;
      margin-right: 10 * $unit;
    }

    .button-text {
      font-size: var(--onfido-font-size-x-small);
      position: relative;
      top: -1.65 * $unit-x-small;
    }
  }
}

.expanded {
  .button {
    top: auto;
    bottom: 16 * $unit;
    font-size: inherit;

    &::before {
      width: 18 * $unit;
      height: 18 * $unit;
      margin-top: -3 * $unit;
      margin-right: 8 * $unit;
      background-image: url('./assets/collapse.svg');
    }
  }
}

.button-overlay {
  background-color: $color-small-button !important;
  border-radius: 4 * $unit;
  color: $color-white;
  font: inherit;
  font-weight: 600;
  height: auto !important;
  line-height: 1.4;
  padding: 11.2 * $unit 19.6 * $unit;

  &:hover {
    background-color: $color-small-button-hover;
  }

  &:active {
    background-color: $color-small-button-active;
  }
}
