@import '../Theme/constants';

.container {
  border-radius: 6 * $unit;
  text-align: left;
  color: $color-white;
  margin-bottom: 16 * $unit;
  padding: 8 * $unit 16 * $unit 12 * $unit 16 * $unit;
  position: relative;
  @media (--small-viewport) {
    margin-bottom: 8 * $unit;
  }
}

.container-error {
  background-color: var(--osdk-color-background-alert-error);
  color: var(--osdk-color-content-alert-error);
}

.container-warning {
  background-color: var(--osdk-color-background-alert-info);
  color: var(--osdk-color-content-alert-info);
}

.instruction {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 24 * $unit;
}

.instruction-text {
  font-size: var(--onfido-font-size-small);
  line-height: 1.43;
}

.title {
  position: relative;
}

.title-text {
  display: inline-block;
  font-weight: 600;
  line-height: 1.5;
  padding-left: 24 * $unit;
}

.title-icon {
  position: absolute;
  height: 100%;
  width: 16 * $unit;
  margin-right: 8 * $unit;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.title-icon-error {
  background-image: url('./assets/error.svg');
}

.title-icon-warning {
  background-image: url('./assets/warning.svg');
}

.roundedTriangle {
  position: absolute;
  bottom: -6 * $unit;
  left: calc(50% - #{8 * $unit});
  width: 8 * $unit;
  height: 8 * $unit;
  border-top: 8 * $unit solid transparent;
  border-right: 8 * $unit solid transparent;
  border-bottom: 8 * $unit solid transparent;
  border-left: 8 * $unit solid transparent;
  border-bottom-left-radius: 3 * $unit;
  transform: rotate(-45deg);
}

.warningTriangle {
  border-bottom: 8 * $unit solid var(--osdk-color-background-alert-info);
  border-left: 8 * $unit solid var(--osdk-color-background-alert-info);
}

.errorTriangle {
  border-bottom: 8 * $unit solid var(--osdk-color-background-alert-error);
  border-left: 8 * $unit solid var(--osdk-color-background-alert-error);
}

.dismiss {
  background-image: url('./assets/cross.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  border-radius: 50%;
  width: 24 * $unit;
  height: 24 * $unit;
  position: absolute;
  top: 10 * $unit;
  right: 10 * $unit;
  cursor: pointer;
  border: 0;
  font: inherit;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.7);
  }
}
