/* stylelint-disable function-parentheses-space-inside */
:global {
  .onfidoLoaderSvg {
    .left {
      opacity: 1;
      animation: onfido-sdk-ui-Auth-left-animate 2s ease-in-out infinite;
    }

    .top {
      opacity: 1;
      animation: onfido-sdk-ui-Auth-top-animate 2s ease-in-out infinite;
    }

    .right {
      opacity: 1;
      animation: onfido-sdk-ui-Auth-right-animate 2s ease-in-out infinite;
    }
  }

  .centre {
    transform-origin: center;
    d: path(
      'M 28 52 C 34.625 52 40.625 49.313 44.969 44.969 C 49.313 40.625 52 34.625 52 28 C 52 24.688 51.328 21.531 50.113 18.66 C 48.898 15.789 47.141 13.203 44.969 11.031 C 42.797 8.859 40.211 7.102 37.34 5.887 C 34.469 4.672 31.313 4 28 4 C 21.375 4 15.375 6.688 11.031 11.031 C 6.688 15.375 4 21.375 4 28 C 4 34.625 6.688 40.625 11.031 44.969 C 15.375 49.313 21.375 52 28 52 C 28 52 28 52 28 52'
    );
    animation: onfido-sdk-ui-Auth-centre 0.5s ease-in-out;
  }

  .tick {
    transform-origin: center;
    opacity: 1;
    animation: onfido-sdk-ui-Auth-tick 0.5s ease-in-out;
    d: path(
      'M 24.94 35.61 L 38.27 22.28 L 36.38 20.39 L 24 32.78 L 18.28 27.06 L 16.39 28.95 L 23.06 35.62 C 23.31 35.86 23.65 36 24 36 C 24.35 36 24.69 35.86 24.94 35.61 Z'
    );
  }
}

@keyframes tick {
  0% {
    transform: scale(0);
    opacity: 0;
    d: path(
      'M 23.86 33.98 L 24.59 33.25 L 24.49 33.15 L 23.81 33.83 L 23.49 33.51 L 23.39 33.61 L 23.76 33.98 C 23.77 33.99 23.79 34 23.81 34 C 23.83 34 23.85 33.99 23.86 33.98 Z'
    );
  }

  51% {
    transform: scale(1.5);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
    d: path(
      'M 24.94 35.61 L 38.27 22.28 L 36.38 20.39 L 24 32.78 L 18.28 27.06 L 16.39 28.95 L 23.06 35.62 C 23.31 35.86 23.65 36 24 36 C 24.35 36 24.69 35.86 24.94 35.61 Z'
    );
  }
}

@keyframes centre {
  0% {
    d: path(
      'M 28.011 34.71 C 29.43 34.712 30.815 34.264 31.964 33.43 C 33.112 32.596 33.967 31.418 34.405 30.067 C 34.842 28.717 34.84 27.262 34.398 25.912 C 33.957 24.563 33.098 23.388 31.946 22.558 C 30.795 21.727 29.409 21.283 27.989 21.29 C 27.989 21.29 27.989 21.29 27.989 21.29 C 26.57 21.288 25.185 21.736 24.036 22.57 C 22.888 23.404 22.033 24.582 21.595 25.933 C 21.158 27.283 21.16 28.738 21.602 30.088 C 22.043 31.437 22.902 32.612 24.054 33.442 C 25.205 34.273 26.591 34.717 28.011 34.71'
    );
  }

  51% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
    d: path(
      'M 28 52 C 34.625 52 40.625 49.313 44.969 44.969 C 49.313 40.625 52 34.625 52 28 C 52 24.688 51.328 21.531 50.113 18.66 C 48.898 15.789 47.141 13.203 44.969 11.031 C 42.797 8.859 40.211 7.102 37.34 5.887 C 34.469 4.672 31.313 4 28 4 C 21.375 4 15.375 6.688 11.031 11.031 C 6.688 15.375 4 21.375 4 28 C 4 34.625 6.688 40.625 11.031 44.969 C 15.375 49.313 21.375 52 28 52 C 28 52 28 52 28 52'
    );
  }
}

@keyframes left-animate {
  0% {
    opacity: 1;
  }

  12.5% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes top-animate {
  0% {
    opacity: 1;
  }

  12.5% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }
}

@keyframes right-animate {
  0% {
    opacity: 1;
  }

  12.5% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
/* stylelint-enable function-parentheses-space-inside */
