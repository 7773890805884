@import '../Theme/constants';

.container {
  display: flex;
  flex-direction: column;
}

.introIcon {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 48 * $unit;
  height: 48 * $unit;
}

/* Follow icon styling in Uploader/style.scss for Selfie Intro icons */
.selfieIcon {
  background-size: 48 * $unit;
  background-image: url('./assets/selfie-picto.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--osdk-color-background-icon);
  border-radius: 50%;
}

.glassesIcon {
  background-size: 48 * $unit;
  background-image: url('./assets/glasses-picto.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--osdk-color-background-icon);
  border-radius: 50%;
}

.introBullet {
  padding-left: 48 * $unit;
  margin-bottom: 48 * $unit;
  position: relative;
  text-align: left;

  @media (--short-viewport) {
    margin-bottom: 32 * $unit;
  }

  &:last-child {
    margin-bottom: 0;
  }

  /* line connecting icons on Selfie Intro screen */
  &::before {
    content: '';
    display: block;
    background-color: $color-icons-connector-line;
    width: 2 * $unit;
    height: 150%;
    position: absolute;
    top: 24 * $unit;
    left: 23 * $unit;
  }

  &:last-child::before {
    display: none;
  }
}

.introBullets {
  padding-left: 0;
  margin-top: 41 * $unit;
  display: table;
  list-style: none;

  @media (--short-viewport) {
    margin-top: 16 * $unit;
  }
}

.introText {
  padding-left: 17 * $unit;
  box-decoration-break: clone;
  height: 48 * $unit;
  display: table-cell;
  vertical-align: middle;
}

.introCopy {
  margin-bottom: auto;
  overflow: auto;
}
