@import '../Theme/constants';

%_icon {
  height: 144 * $unit;
  width: 144 * $unit;
  background-size: contain;
  margin-top: 56 * $unit;
  margin-left: auto;
  margin-right: auto;
}

.genericErrorIcon {
  @extend %icon-circle-background;
  @extend %_icon;
  background-image: url('./assets/connection-error-large.svg');
}

.flowInterruptedIcon {
  @extend %icon-circle-background;
  @extend %_icon;
  background-image: url('./assets/interrupted-flow.svg');
}

.unsupportedBrowserIcon {
  @extend %_icon;
  background-image: url('./assets/error-cross-small.svg');
  height: 54 * $unit;
}
